import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';

import App from './App';
import Head from "./components/Head";
import Foot from './components/Foot';




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Head/>
    <App/>
    <Foot/>
  </BrowserRouter>
  
);
