import {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';

export default function useListing(listingKey) {

  const {state} = useLocation();
  const [listing, setListing] = useState();

  const fetchListing = async () => {
    let received_listing = await fetch(`${process.env.REACT_APP_PUBLIC_URL}/api/listing/${listingKey}`,
    {
        method: 'GET'
    }).then(data => data.json())
    setListing(received_listing)
}

  useEffect(() => {

    if (state && state.listing) {
      // Use Featured Listings data passed via Link
      setListing(state.listing)

    } else {
      // Fetch Featured Listings data from API
      console.log("fetching listing")
      fetchListing();
    }
  }, []);

  return {listing};
}