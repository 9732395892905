import {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';

export default function useFeatured() {

  const {state} = useLocation();
  const [Featured, setFeatured] = useState([]);

  const fetchFeaturedListings = async () => {
    let received_listings = await fetch(`${process.env.REACT_APP_PUBLIC_URL}/api/listings`,
    {
        method: 'GET'
    }).then(data => data.json())
    setFeatured(received_listings)
    
  }

  useEffect(() => {

    if (state && state.featuredListings) {
      // Use Featured Listings data passed via Link
      setFeatured(state.featuredListings)
      console.log(Featured)

    } else {
      // Fetch Featured Listings data from API
      fetchFeaturedListings();

    }
  }, []);

  return {Featured};
}