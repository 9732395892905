import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'

import { BsChevronLeft, BsChevronCompactRight } from 'react-icons/bs'
import { Stack } from 'react-bootstrap';

import ErrorBoundary from '../ErrorBoundary';

export default function ListingCard({listing}) {
    const { pathname }  = useLocation();
    const [primaryImage, setPrimaryImage] = useState("");

    

    useEffect(() => {
        if (listing.Media && listing.Media.at(0)) {
            setPrimaryImage(listing.Media.at(0).MediaURL)
        }
    }, [listing])

    return(
        <ErrorBoundary>
        <Col xs={12} md={6} lg={4} xxl={3}>
            
            {/** 
             * 
             * 
             *      Card View
             *      - Initially rendered component
             *      -----------------------------------------------------
             * 
             * 
             *      TODO:
             *          This component (ListingCard) may be rendered at different routes in the website, 
             *          so the underlying Link component may need to be modified
             */}
            <Link 
            to={pathname == "/" ? `./buy/${listing.ListingKey}` : `./${listing.ListingKey}`}
            state={{ listing: listing}}
            style={{ textDecoration: 'none' }}
            >
            <Card>
                <Card.Header style={{backgroundImage: `url(${primaryImage})`}}></Card.Header>
                <Card.Body className='listing-card-body'>
                    <h1>
                        {listing.Address ? listing.Address.substring(0,26) : ""}
                    </h1>
                    <p>{listing.City}</p>
                    <h2>{
                            listing.ListPrice ?
                            `$${listing.ListPrice.toLocaleString()}`
                            : <></>
                    }</h2>
                    <Stack direction='horizontal'>
                        {listing.BedroomsTotal ? listing.BedroomsTotal + " Bed" : " - "}
                        <BsChevronCompactRight/>
                        {listing.BathroomsTotalInteger ? listing.BathroomsTotalInteger + " Bath" : " - "}
                        <BsChevronCompactRight/>
                        {listing.LivingArea ? listing.LivingArea + ` ${listing.LivingAreaUnits ? listing.LivingAreaUnits : ""}` : " - "}
                    </Stack>
                </Card.Body>
            </Card>
            </Link>
        </Col>
        </ErrorBoundary>
    );
}