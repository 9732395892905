import { Container, Row, Col } from "react-bootstrap";
import Image from 'react-bootstrap/Image'
import RealtorCard from "./RealtorCard";
import stockPhoto from "./Images/stockPhoto.png"
import Tammy from "./Images/Tammy.jpg"
import Alba from "./Images/Alba.jpg"
import Damaris from "./Images/Damaris.jpg"
import Melissa from "./Images/Melissa.jpg"
import Yoly from "./Images/Yoly.jpg"

import Head from "../Head";

export default function About_Page() {

    const RealtorArray = [
        {
            name: "Tammy Schneider",
            title: "Broker Owner",
            phone: "305-790-4794",
            email: "Tammy@MiamiHomesteadRealty.com",
            img: Tammy,
        },
        {
            name: "Alba Dana",
            title: "Realtor",
            phone: "305-301-7622",
            email: "Alba@MiamiHomesteadRealty.com",
            img: Alba,

        },
        {
            name: "Melissa Hernandez",
            title: "Realtor",
            phone: "305-300-9445",
            email: "Melissa@MiamiHomesteadRealty.com",
            img: Melissa,
        },
        {
            name: "Damaris Martinez",
            title: "Realtor",
            phone: "305-726-3403",
            email: "Damaris@MiamiHomesteadRealty.com",
            img: Damaris,
        },
        {
            name: "Timothy Wirth",
            title: "Realtor",
            phone: "305-446-5603",
            email: "Wirthrealty@aol.com",
        },
        {
            name: "Ana \"Bonnie\" Amenabar ",
            title: "Realtor",
            phone: "305-498-9313",
            email: "Bonnie.Amenabar@gmail.com",
        },
        
    ];

    return(
        <>
        <Container>
            <Row className="pageSubTitle"> About Us </Row>

            <Row className="aboutInfo">

                <Col lg={6} >
                    <br/>
                    <h2 className="compName">Miami Homestead Realty, Inc.</h2>
                    <p> 7400 SW 50 Terrace, #204 <br/> Miami, 33155</p>
                    <p> Phone: <br/> (305) 473-2589 / (305) 4-REALTY </p>
                    <p> Email: <br/> tammy@miamihomesteadrealty.com </p>
                </Col>
            </Row>

            <Row className="pageSubTitle"> Our team </Row>

            <Row className="g-4"> {RealtorArray.map((Realtor, index) => (
                    <RealtorCard RealtorInfo={Realtor} key={index}/>
                ))} </Row>

            <Row className="pageSubTitle"></Row>
        </Container>
        </>
    );
}