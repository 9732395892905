
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";

import HeadLogo from "./HeadLogo.jpg"
import Alba from "./About_Page/Images/Alba.jpg"

export default function Head() {

    const subDomainIsAlba = () => {

        const subDomain = window.location.host.split('.'[0]);
        if (subDomain === "albadana") return true;
        else return false;
    }
    
    return(
        <div className="head">
            <Container >
                <Row >
                    <Col xs={10} lg="auto">
                        <a href="/" className="mainTitle">
                            <img src={HeadLogo}></img>
                        </a>
                    </Col>
                    {subDomainIsAlba() ? 
                    <Col xs={2} className='subDomainHeadPiece'>
                        <img src={Alba} width={100}></img>
                    </Col>
                    :<></>}
                </Row>
                
                <Row>
                    <Col>
                        <Link to='/'>
                            <Button variant="light" className="navOption"> Home </Button>
                        </Link>
                        <Link to='/buy'>
                            <Button variant="light" className="navOption"> Buy </Button>
                        </Link>
                        <Link to="/rent">
                            <Button variant="light"  className="navOption"> Rent </Button>           
                        </Link>      
                        <Link to="/about">
                            <Button variant="light"  className="navOption"> About </Button>           
                        </Link>   
                    </Col>
                </Row>
            </Container>
        </div>
    );
}