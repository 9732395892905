import { Col, Container, Row } from "react-bootstrap";

export default function Error() {

    return(
        <Container className="column page">
            <Row>
                <Col>
                    An Error Was Encountered                
                </Col>
            </Row>
        </Container>
    );
}