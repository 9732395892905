
import { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Container, Row, Col, Collapse, Button, Spinner} from "react-bootstrap";
import Alert from 'react-bootstrap/Alert';

import ListingCard from "../ListingCard";
import ErrorBoundary from "../../ErrorBoundary";
import useFeatured from "../../useFeatured";
import useSearch from "../../useSearch";
import Controls from "./Controls";


export default function Search_Page({SearchType, QTextParam}) {

    const {Featured} =  useFeatured();
    const { doSearch } = useSearch();
    // const { state } = useLocation();
    const [SearchResults, setSearchResults]  = useState();
    const [SearchError, setSearchError] = useState(null);
    const [Loading, setLoading] = useState(false);

    const [TextParam, setTextParam] = useState(QTextParam);
    // const [TextParam, setTextParam] = useState(localStorage.getItem('TextParam'));
    const [BuyRent, setBuyRent] = useState(SearchType);
    const [MinPrice, setMinPrice] = useState("");
    const [MaxPrice, setMaxPrice] = useState("");
    const [MinBed, setMinBed] = useState(0);
    const [MinBath, setMinBath] = useState(0);
    const [House, setHouse] = useState(true);
    const [Townhouse, setTownhouse] = useState(true);
    const [Apartment, setApartment] = useState(true);
    const [MultiFamily, setMultiFamily] = useState(false);

    

    useEffect(() => {
        if (SearchType === "Buy" || SearchType === "Rent") {
            setMinPrice("")
            setMaxPrice("")
            setBuyRent(SearchType)
        }
    }, [SearchType])

    // useEffect(() => {

    //     const takenTextParam = localStorage.getItem('TextParam')
    //     console.log("takenTextParam = " + takenTextParam)
    //     setTextParam(takenTextParam)
    //     onSearch();
    //     if (state && state.TextParam) {
    //         console.log("Setting TextParam (value: " + state.TextParam + ") from state")
    //         setTextParam(state.TextParam)
    //         // setTextParam("<value that should be from 'state'>")
    //         console.log(state)
    //         console.log("now TextParam is " + TextParam)
    //         onSearch()
    //     }
    // }, [])

    const onSearch = async () => {
        setLoading(true)
        const newResults = await doSearch({TextParam, BuyRent, MinPrice, MaxPrice, MinBed, MinBath, House, Townhouse, Apartment, MultiFamily})
        if (!newResults.message){
            
            setSearchResults(newResults)
            console.log("Setting search results: " + newResults)
            setSearchError()
        } else {
            setSearchError(newResults)
        }
        setLoading(false)
    }

    useEffect(() => {
        console.log("SEARCH PARAMS CHANGED")
        console.log("TextParam: " + TextParam)
        onSearch();
    }, [TextParam, BuyRent, MinPrice, MaxPrice, MinBed, MinBath, House, Townhouse, Apartment, MultiFamily])


    const onFindMore = async () => {
        setLoading(true)
        const skip = SearchResults.length;
        console.log("skip = " + skip)
        const newResults = await doSearch({TextParam, BuyRent, MinPrice, MaxPrice, MinBed, MinBath, House, Townhouse, Apartment, MultiFamily}, skip)
        if (!newResults.message){
            if (SearchResults) setSearchResults(SearchResults.concat(newResults))
            setSearchError()
        } else {
            setSearchError(newResults)
        }
        setLoading(false)
    }

    return(
        <Container>
            <Outlet/>
            <Controls SearchState={{
                    TextParam, setTextParam, 
                    BuyRent,
                    MinPrice, setMinPrice, 
                    MaxPrice, setMaxPrice, 
                    MinBed, setMinBed, 
                    MinBath, setMinBath, 
                    House, setHouse, 
                    Townhouse, setTownhouse, 
                    Apartment, setApartment,
                    MultiFamily, setMultiFamily,
                    Loading}} SearchFuncs={{onSearch}}/>
            
            <Collapse in={SearchError ? true : false}>
                <Row className="justify-content-md-center ">
                <Col  md={6}>
                    <Alert variant="warning">
                        {SearchError ? SearchError.message : ""}
                    </Alert>
                </Col>      
                </Row>
            </Collapse>

            <Row className="page g-4">
                {SearchResults && SearchResults.length > 0 ? 
                    SearchResults.map((listing, index) => (<ListingCard listing={listing} key={index} />))
                    :
                    <></>
                }
            </Row>
            <Row className="pageSubTitle">
                <Col md="auto">
                    <Button onClick={onFindMore}>
                        {Loading ? 
                        <> <Spinner size="sm"/> {` Loading`} </> 
                        : "Find More"}
                    </Button>
                </Col>
            </Row>

            <Row className='pageSubTitle'> Featured Listings </Row>
            <Row className="g-4">
                {Featured && Featured.length? 
                Featured.map((listing, index) => ( <ErrorBoundary key={index}> <ListingCard listing={listing} /> </ErrorBoundary>))
                : <Spinner></Spinner>}
            </Row>
            <Row className='pageSubTitle'>

            </Row>
        </Container>
        
    );
}