import React from 'react';
import './App.css';

import { Routes, Route, useLocation } from "react-router-dom";

import Home_Page from "./components/Home_Page/Home_Page";
import Error from "./components/Error";

import Login from "./components/Login";
import ListingModal from './components/ListingModal';

import ErrorBoundary from './ErrorBoundary';
import Search_Page from './components/Search_Page/Search_Page';
import About_Page from './components/About_Page/About_Page';



// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}


function App() {

  const _QTextparam = useQuery().get("TextParam");

  return (
    <ErrorBoundary>
      <Routes>
        <Route path="/" element={ <App/> }/>
        <Route path="/login" element={ <Login/> }/>



        <Route path="/buy" element={ <Search_Page SearchType={"Buy"} QTextParam={_QTextparam ? _QTextparam : ""}/> } >
          <Route path=":listingKey" element={<ListingModal/>}/>
        </Route>
        <Route path="/rent" element={ <Search_Page SearchType={"Rent"} QTextParam={_QTextparam ? _QTextparam : ""}/> } >
          <Route path=":listingKey" element={<ListingModal/>}/>
        </Route>



        <Route path="/about" element={ <About_Page/> } />
        <Route index element={ <Home_Page/> }/>
        <Route path="*" element={ <Error/> }/>
      </Routes>
    </ErrorBoundary>
    
  );
}

export default App;
