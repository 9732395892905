import { useState, useEffect } from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import { Container, Stack } from 'react-bootstrap';

import { BsChevronLeft, BsChevronCompactRight } from 'react-icons/bs'

import { Link, useLocation, useParams } from 'react-router-dom';
import useListing from '../useListing';
import ErrorBoundary from '../ErrorBoundary';

export default function ListingModal() {

    const { listingKey } = useParams();
    const { listing } = useListing(listingKey);

    const [imageModalIsOpen, setImageModalIsOpen] = useState(false);
    const [imageModalIndex, setImageModalIndex] = useState(0);

    const onOpenImageModal = () => {
        setImageModalIsOpen(true)
    }

    const onCloseImageModal = () => {
        setImageModalIsOpen(false)
    }
    
    const handleSelect = (selectedIndex, e) => {
        setImageModalIndex(selectedIndex);
    }

    if(listing && !imageModalIsOpen) {
        return(
            <ErrorBoundary>
            <Modal 
            show={true}
            size={'xl'}
            >
                <Modal.Header >
                    <Link to="..">
                        <Button >
                            <BsChevronLeft/> Back
                        </Button>
                    </Link>
                </Modal.Header>
                <Modal.Body>
                <Row className='listingFullContent'>
                    <Col>
                        <h3>{listing.ListingType}</h3>
                        <h1 className="orangeEmph margin-zero">
                            {listing.ParsedAddress ? 
                            listing.ParsedAddress[0]
                            : ""}
                        </h1>
                        <p> {listing.City}</p>

                        <h2 className="price margin-zero">
                            {
                            listing.ListPrice ?
                            `$${listing.ListPrice.toLocaleString()}`
                            : <></>
                            }
                        </h2>
                        <p> {listing.StandardStatus} </p>
                        <h4> - Bedrooms: {listing.BedroomsTotal}</h4>
                        <h4> - Bathrooms: {listing.BathroomsTotalDecimal}</h4>
                        {listing.LivingArea ? <h4> - Living Area: {listing.LivingArea.toLocaleString()} {listing.LivingAreaUnits}</h4> : <></>}
                        
                        <h4> - Year Built: {listing.YearBuilt}</h4>
                        <br/>
                        <p>{listing.PublicRemarks}</p>
                        <p>This listing is courtesy of: {listing.ListOfficeName}</p>
                        <br/>
                        <h4>Contact us about this property: <br/>(305) 473-2589

                        </h4>
                    </Col>

                    <Col lg={6}>
                        {listing.Media.map((mediaItem, index) => (
                            <img src={mediaItem.MediaURL} width={index == 0 ? "100%" : "50%"} key={index} onClick={() => {
                                onOpenImageModal()
                                setImageModalIndex(index)
                            }}></img>
                        ))}
                    </Col>
                </Row>
                </Modal.Body>

                
            
            </Modal>
            </ErrorBoundary>
        );
    } else if (listing && imageModalIsOpen) {
        return (
            <ErrorBoundary>
            <Modal show={imageModalIsOpen} 
            size={'xl'} 
            onExit={onCloseImageModal}
            >
                <Modal.Header>
                    <Button onClick={onCloseImageModal} > 
                        <BsChevronLeft /> Back
                    </Button>
                </Modal.Header>
                <Modal.Body>
                    

                    <Row>
                        <Col>
                            <Carousel activeIndex={imageModalIndex} onSelect={handleSelect} interval={null}>
                                {listing.Media.map((mediaItem, index) => (
                                    <Carousel.Item key={index}>
                                        <img className="d-block w-100" src={mediaItem.MediaURL} alt="image"/>
                                        <Carousel.Caption>
                                            <p>{mediaItem.ShortDescription}</p>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
            </ErrorBoundary>
        );
    }else {
        <h1>not here</h1>
    }
}