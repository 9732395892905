import {useState} from 'react';

import Home from './Home_Page/Home_Page'
import useToken from './../useToken'

async function loginUser(credentials) {
    return fetch(`${process.env.REACT_APP_API_URL}users/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    }) 
        .then(data => data.json())
}

export default function Login(){

    const { token, setToken } = useToken();
    const [failedAttempt, setFailedAttempt] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const handleSubmit = async e => {

        e.preventDefault();
        const loginResponse = await loginUser({
          email,
          password
        });
        if (loginResponse.token) {
            console.log("received token: " + loginResponse.token)
            setToken({
                token: loginResponse.token
            });
            setFailedAttempt(null);
        }else {
            console.log(loginResponse);
            if (loginResponse.message){
                setFailedAttempt(loginResponse.message);
            } else {
                setFailedAttempt("Unknown error");
            }
            
        }
    }

    
    return(
        <form onSubmit={handleSubmit} className="column loginForm">
            <label>Email</label>
            <input type="text" onChange={e => setEmail(e.target.value)}/>
            <label>Password</label>
            <input type="text" onChange={e => setPassword(e.target.value)}/>
            <input type="submit"/ >
            {failedAttempt ? 
            <h3>{failedAttempt}</h3>
            :
            <></>}
            {token ?
            <>
                <h3>
                    Success
                </h3>
                <a href='/dashboard' className='button'>
                    Go to admin page
                </a>
            </>
            : <></>}
        </form>
    );
    

    
}
