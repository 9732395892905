import React from 'react'
import Error from './components/Error';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }    

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    render() {
        if(this.state.hasError) {
            return <Error/>
        }
        return this.props.children;
    }
}

export default ErrorBoundary;