// import { compare } from "bcryptjs";
import { Row, Col, Container } from "react-bootstrap";


export default function Foot() {
    return(
        <div className="grey">
        <Container>
            <Row className="pageSubTitle"></Row>
            <Row>
                <Col md="auto">
                Copyright Southeast Florida Multiple Listing Service © 2023. Accuracy of listing information is not guaranteed. Listing information is provided for personal consumer, non-commercial use, solely to identify potential properties for potential purchase. All other use is strictly prohibited, is a violation of the terms of use, and may violate federal and state law.
                </Col>
            </Row>
            <Row className="pageSubTitle"></Row>

        </Container>
        </div>

    );
}