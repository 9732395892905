import { Card, Col } from "react-bootstrap";
import ErrorBoundary from "../../ErrorBoundary";
import RealtorFiller from "./Images/RealtorFiller.png"
import Image from 'react-bootstrap/Image'

export default function RealtorCard({RealtorInfo}) {
    return(
        <ErrorBoundary>
            <Col className="realtorCard">
                <Card>
                    <Card.Body>
                        <Image src={RealtorInfo.img ? RealtorInfo.img : RealtorFiller } roundedCircle></Image>
                        <h2> {RealtorInfo.name} </h2>
                        <h1> {RealtorInfo.title} </h1>
                        <h3> {RealtorInfo.phone} </h3>
                        <h3> {RealtorInfo.email} </h3>
                    </Card.Body>
                </Card>
            </Col>
        </ErrorBoundary>
    );
}