import { useState, useEffect } from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Spinner, Stack } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';


import ErrorBoundary from '../../ErrorBoundary';
import ListingCard from '../ListingCard';
import { Link } from 'react-router-dom';
import useFeatured from '../../useFeatured';
import Head from '../Head';

export default function Home_Page() {

    const {Featured} = useFeatured();

    const [TextParam, setTextParam] = useState("");
    const [searchValid, setSearchValid] = useState(false);

    const handleSearch = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
      
        setSearchValid(true);
    }

    return(
        <>

            <div className='blue'>

                <Container>
                    <Stack direction="horizontal" gap={3} className="searchBar">
                        {/* <Form.Control 
                            name="Search String"
                            type='text'
                            placeholder='Enter zip code' 
                            className='searchInput'
                            value={TextParam}
                            onChange={(event) => {
                                setTextParam(event.target.value)
                            }}/>

                        <Link to='/buy'
                        state={{ featuredListings: Featured, 
                            TextParam: TextParam, }}>
                            <Button type='submit' className='big'> Search</Button>
                        </Link> */}

                        <InputGroup style={{width: "250px"}} >
                            <Form.Control 
                                name="Search String"
                                type='text'
                                placeholder='Enter zip code' 
                                className='searchInput'
                                value={TextParam}
                                onChange={(event) => {
                                    setTextParam(event.target.value)
                                }}/>

                            <Button type='submit' 
                                className='big' >
                                <Link to={`/buy?TextParam=${TextParam}`}
                                className='btn-primary'
                                >
                                    Search
                                </Link>
                            </Button> 

                            {/*<Button type='submit' className='big'> 
                                <Link to='/buy'
                                className='btn-primary'
                                >
                                    Search South Florida
                                </Link>
                        </Button>*/}
                        </InputGroup>
                        
                    </Stack>

                    
                </Container>
            </div>
            <ErrorBoundary>
            <Container >
                <Row className='pageSubTitle'> Featured Listings </Row>
                <Row className="g-4">
                    {Featured && Featured.length? 
                    Featured.map((listing, index) => ( <ErrorBoundary key={index}> <ListingCard listing={listing} /> </ErrorBoundary>))
                    : <Spinner></Spinner>}
                </Row>
                <Row className='pageSubTitle'>

                </Row>
            </Container>
            </ErrorBoundary>
        </>
        
    );
}