import { Row, Col, Button, ButtonGroup, Spinner } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import InputGroup from 'react-bootstrap/InputGroup';
import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";


export default function Controls({SearchState, SearchFuncs}) {
    const {TextParam, setTextParam, 
        BuyRent,  
        MinPrice, setMinPrice, 
        MaxPrice, setMaxPrice, 
        MinBed, setMinBed, 
        MinBath, setMinBath, 
        House, setHouse, 
        Townhouse, setTownhouse, 
        Apartment, setApartment,
        MultiFamily, setMultiFamily,
        Loading} = SearchState;

    const {onSearch} = SearchFuncs;

    const [tempMaxPrice, setTempMaxPrice] = useState(MaxPrice);
    const [tempMinPrice, setTempMinPrice] = useState(MinPrice);
    const [tempTextParam, setTempTextParam] = useState(TextParam);

    const hold = (event) => {
        event.preventDefault();
        event.stopPropagation();
    }

    const getHomeTypesString = () => {
        let HomeTypes = [];
        if (House) HomeTypes.push("House")
        if (Townhouse) HomeTypes.push("Town")
        if (Apartment) HomeTypes.push("Apt")
        if (MultiFamily) HomeTypes.push("MultiFam")
        if (HomeTypes.length === 0) {
            return "Types";
        }
        let HomeTypesString = "";
        HomeTypes.forEach((type, index) => {
            if (index === 0) {
                HomeTypesString = HomeTypesString.concat(type)
            } else {
                HomeTypesString = HomeTypesString.concat("/" + type)
            }
        })
        return HomeTypesString;
    }

    const getPriceRange = () => {
        if (!MinPrice && !MaxPrice) {
            return "Price Range";
        }
        if (MaxPrice && MinPrice) {

            return `$${Number(MinPrice).toLocaleString()} - $${Number(MaxPrice).toLocaleString()}`;
        }
        if (MinPrice) {
            return `$${Number(MinPrice).toLocaleString()} or more`;
        }
        if (MaxPrice) {
            return "$" + Number(MaxPrice).toLocaleString() + ` or less`;;
        }
    }

    return(
        <Row className="pageSubTitle justify-content-md-center g-3"> 
            
            <Col xs="auto">

                <InputGroup style={{width: "250px"}} >
                    <Form.Control
                    type='text'
                    placeholder='Zip code' 
                    value={tempTextParam}
                    onChange={(event) => {
                        setTempTextParam(event.target.value)
                    }}/>

                    <Button 
                    variant={TextParam === tempTextParam ? "light" : "primary"}
                    onClick={() => {
                        console.log("Click search")
                        setTextParam(tempTextParam)
                        onSearch()
                    }}> 
                    {Loading ? 
                    <> <Spinner size="sm"/> {` Loading`} </> 
                    : "Search"} </Button>
                </InputGroup>
                
                
            </Col>
            <Col xs="auto">
                <ButtonGroup>
                    <Link to="/buy">
                        <Button variant={BuyRent === "Buy" ? "secondary" : "light"} >
                            Buy
                        </Button>
                    </Link>
                    <Link to="/rent">
                        <Button variant={BuyRent === "Rent" ? "secondary" : "light"} >
                            Rent
                        </Button>
                    </Link>
                </ButtonGroup>
            </Col>
            <Col xs="auto">
                <Dropdown >
                    <Dropdown.Toggle variant="light" id="bedrooms-dropdown">
                        {getHomeTypesString()}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item eventKey={0} as={"div"} active = {House ? true : false} onClick={(event) => {
                            setHouse(!House)
                            hold(event)
                        }}>House</Dropdown.Item>
                        <Dropdown.Item eventKey={1} as={"div"} active = {Townhouse ? true : false} onClick={(event) => {
                            setTownhouse(!Townhouse)
                            hold(event)
                        }}>Townhouse</Dropdown.Item>
                        <Dropdown.Item eventKey={2} as={"div"} active = {Apartment ? true : false} onClick={(event) => {
                            setApartment(!Apartment)
                            hold(event)
                        }}>Apartment/Condo</Dropdown.Item>
                        <Dropdown.Item eventKey={3} as={"div"} active = {MultiFamily ? true : false} onClick={(event) => {
                            setMultiFamily(!MultiFamily)
                            hold(event)
                        }}>MultiFamily</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Col>

                {/**
                 * 
                 * 
                 *      ---------------------------------------------
                 *      Bed & Bath Range 
                 *      ---------------------------------------------
                 */}
            <Col xs="auto">
                <Dropdown onSelect={(eventKey) => {
                    setMinBed(eventKey)
                }}>
                    <Dropdown.Toggle variant="light" id="bedrooms-dropdown">
                        {MinBed === 0 ? "Any " : `${MinBed}+ `} Bed
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item eventKey={0} as={"div"}>Any</Dropdown.Item>
                        <Dropdown.Item eventKey={1} as={"div"}>1+</Dropdown.Item>
                        <Dropdown.Item eventKey={2} as={"div"}>2+</Dropdown.Item>
                        <Dropdown.Item eventKey={3} as={"div"}>3+</Dropdown.Item>
                        <Dropdown.Item eventKey={4} as={"div"}>4+</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
            <Col xs="auto">
                <Dropdown onSelect={(eventKey) => {
                    setMinBath(eventKey)
                }}>
                    <Dropdown.Toggle variant="light" id="bedrooms-dropdown">
                        {MinBath === 0 ? "Any " : `${MinBath}+ `} Bath
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item eventKey={0} as={"div"}>Any</Dropdown.Item>
                        <Dropdown.Item eventKey={1} as={"div"}>1+</Dropdown.Item>
                        <Dropdown.Item eventKey={2} as={"div"}>2+</Dropdown.Item>
                        <Dropdown.Item eventKey={3} as={"div"}>3+</Dropdown.Item>
                        <Dropdown.Item eventKey={4} as={"div"}>4+</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
            <Col xs="auto">
                {/**
                 * 
                 * 
                 *      ---------------------------------------------
                 *      Price Range 
                 *      ---------------------------------------------
                 */}

                <Dropdown >
                    <Dropdown.Toggle variant="light">
                        {getPriceRange()}
                    </Dropdown.Toggle>
                    <Dropdown.Menu style={{width: "190px"}}>
                        <Dropdown.Item 
                        as={"div"}
                        className="flat-dropdown-item"
                        onClick={hold}>
                            Max
                            <InputGroup>
                                <InputGroup.Text>$</InputGroup.Text>
                                <Form.Control
                                type="text"
                                value={tempMaxPrice}
                                placeholder="None"
                                onChange={(event) => {
                                    setTempMaxPrice(event.target.value)
                                }}
                                />
                            </InputGroup>
                        </Dropdown.Item>
                        
                        <Dropdown.Item 
                        as={"div"}
                        className="flat-dropdown-item"
                        onClick={hold}>
                            Min
                        
                            <InputGroup>
                                <InputGroup.Text>$</InputGroup.Text>
                                <Form.Control
                                type="text"
                                value={tempMinPrice}
                                placeholder="None"
                                onChange={(event) => {
                                    setTempMinPrice(event.target.value)
                                }}
                                />
                            </InputGroup>
                        </Dropdown.Item>

                        <Dropdown.Item 
                        as={"div"}
                        className="flat-dropdown-item"
                        >
                            <Button 
                            style={{width: "100%"}}
                            onClick={() => {
                                setMinPrice(tempMinPrice)
                                setMaxPrice(tempMaxPrice)
                            }}
                            variant={tempMinPrice === MinPrice && tempMaxPrice === MaxPrice ? "light" : "primary"}>
                                Apply</Button>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
        </Row>
    );
}