import {useEffect, useState} from 'react';

export default function useSearch() {

    const [searchResults, setSearchResults] = useState([]);

    const doSearch = async ({TextParam, BuyRent, MinPrice, MaxPrice, MinBed, MinBath, House, TownHouse, Apartment, MultiFamily}, skip) => {

        console.log("Search with following parameters: "
        + "TextParam - " + TextParam + " / "
        + "MinBed - " + MinBed + " / "
        + "MinBath - " + MinBath + " / "
        + "MinPrice - " + MinPrice + " / "
        + "MaxPrice - " + MaxPrice)

        let HomeTypes = [];
        if (House) HomeTypes.push("House")
        if (TownHouse) HomeTypes.push("TownHouse")
        if (Apartment) HomeTypes.push("Apartment")
        if (MultiFamily) HomeTypes.push("MultiFamily")

        const searchBody = {
            TextParam,
            BuyRent, 
            MinPrice,
            MaxPrice,
            MinBed,
            MinBath,
            HomeTypes,
            skip,
        }

        let results = await fetch(`${process.env.REACT_APP_PUBLIC_URL}/api/search`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(searchBody)
        }).then(data => data.json())
        .catch((error) => {
            return {
                message: "Could not connect to server"
            }
        })
        setSearchResults(results)
        return results;
    }

    return {
        searchResults,
        doSearch,
    };
}